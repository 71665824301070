
.side-navbar-container {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 240px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  /* z-index: 1; */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #385395; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
}

/**/

.side-navbar-items {
	display: flex;
	flex-flow: column;
	padding-bottom: 55px;
    height: calc(100vh - 55px);
}

.navbar-item {
	display: flex;
	flex-flow: column;
	text-align: left;
}

.navbar-item-title {
	font-size: 20px;
	color: #fff;
	margin-bottom: 20px;
	margin-left: 30px;
	margin-top: 25px;
}

.navbar-link {
	font-size: 18px;
	color: #fff;
	text-decoration-line: none;
	margin-bottom: 15px;
	margin-left: 50px;
}

.navbar-link:hover {
	cursor: pointer;
	color: #E1E1E1;
}


/**/

.navbar-logo {
	margin-bottom: 10px;
	padding-top: 55px;
}

.logo {
	/* width: 180px; */
}

/**/


.navbar-line-breaker {
    border: 0.3px solid #FFFFFF;
	width: 180px;
	margin-top: auto;
}


/* On screens that are 1280px or less */
@media screen and (max-width: 1280px) {

/*	.navbar-logo {
		margin-top: 25px;
		margin-bottom: 10px;
	}

	.navbar-item h2 {
		font-size: 120%;
		color: #fff;
		margin-bottom: 15px;
		margin-left: 30px;
		margin-top: 25px;
	}

	#navbar-link {
		font-size: 110%;
		color: #fff;
		text-decoration-line: none;
		margin-left: 50px;
	}*/

}
