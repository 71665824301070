body {
	background: rgba(57,83,149,0.10);
	height: 100%;
}

.container {
	margin-top: 50px;
	margin-left: 265px;
	margin-right: 25px;
	margin-bottom: 30px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.header-title-text {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}


/* CLIENTS - header options container */
.header-options-container {
	display: flex;
	flex-flow: row;
}

.header-option: {
	margin-left: 15px;
}

.header-button {

}

.search-input {
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 200;
    border: 1px solid #395395;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
    color: #B6B6B6;
    background-color: #fff;
    border: none;
}

.no-items {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.products-container {
	margin-top: 30px;
	margin-left: 0px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
	padding-bottom: 10px;
}

.products-header {
	height: 50px !important;
	color: #B6B6B6 !important;
}

.products-item {
	color: #4A4A4A;
	background-color: white;
	height: 30px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	font-size: 12px;
}

.products-item:hover {
	opacity: 0.7;
	/* cursor: pointer; */
}

.products-item--stripe {
	background-color: #EBEDF4;
}

.products-item-select {
	width: 3%;
}

.products-item-sku {
	padding-left: 10px;
	text-align: left;
	width: 10%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-name {
	text-align: left;
	width: 12%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.products-item-name:hover {
	cursor: pointer;
}

.products-item-description {
	text-align: left;
	width: 22%;
 	white-space: nowrap;
  	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.products-item-brand {
	text-align: left;
	width: 10%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-price {
	text-align: left;
	width: 7%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-rating {
	text-align: left;
	width: 7%;
}

.products-item-rating-code {
	text-align: left;
	width: 7%;
}

.products-item-stock {
	text-align: left;
	width: 7%;
}

.products-item-actions {
	display: flex;
	flex-flow: row nowrap;
	text-align: left;
	justify-content: flex-end;
	width: 15%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-actions > div {
	margin-right: 10px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-actions > div:hover {
	opacity: 0.5;
	cursor: pointer;
}

.load-more-button {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	height: 20px;
	width: 	100px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
}

.load-more-button:hover {
	background-color: #385395;
	color: white;
	transition: .2s;
}

.product-details {
	font-size: 16px;
}

.product-details-media {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 30px;
}

.product-details-media-item {
	width: 100px;
	height: 100px;
	margin-right: 15px;
	margin-top: 15px;
	border: 1px solid lightgrey;
	border-radius: 10px;
	overflow: hidden;
}

.product-details-media-item:hover {
	cursor: pointer;
}

.product-details-media-item-img {
	/* width: 100px; */
	height: 100px;
	margin-right: 15px;
}

.product-details-name {
	font-size: 26px;
	margin-bottom: 15px;
}

.product-details-brand {
	margin-bottom: 10px;
}

.product-details-description {
	margin-bottom: 20px;
}

.product-details-price-field {
	margin-top: 15px;
	display: flex;
	flex-flow: row nowrap;
}

.product-details-price-field-label {
	margin-right: 10px;
	width: 250px;
}

.product-details-price-field-input {
	width: 50px;
	font-size: 16px;
	text-align: right;
	margin: none;
}

.button {
	margin-top: 10px;
	margin-left: auto;
	/* margin-right: auto; */
	height: 20px;
	width: 	220px;
	color: #fff;
	font-style: normal;
	background-color: #385395;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
	border: 1px solid #385395;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.button:hover {
	border: 1px solid #385395;
	background-color: white;
	color: #385395;
	transition: .2s;
}

.button--header {
	margin-top: 0px;
	margin-right:15px;
	width: 75px;
}

.button--header:hover{
	background-color:transparent;
}

.button--inactive {
	opacity: 0.5
}

.icon {
	margin-left: 8px;
	margin-top: 3px;
}
