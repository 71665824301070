body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-logo-container {
	margin-bottom: 60px;
}

.login-logo {
	width: 380px;
}

.login-container {
	background-color: #385395;
   	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}


.login-email-field, .login-password-field {
	width: 330px;
	margin: .5em;
	padding: 11px 30px 11px 30px;
	border-radius: 30px;
	font-size: 25px;
	border: none;
	color: #fff;
	/* font-style: italic; */
	background-color: #8E9CC1;
	font-weight: 200;
}

.login-container input::-webkit-input-placeholder {
   font-style: italic;
}

.login-container input::-ms-input-placeholder {
	color: #fff;
}

.login-container input::placeholder {
	color: #fff;
}

.sign-in-button {
	height: 50px;
	width: 	295px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	border-radius: 30px;
	margin-top: 35px;
	cursor: pointer;
	border-radius: 30px;
	font-size: 25px;
	border: none;
	font-weight: 300;
}

.sign-in-button:hover {
	background-color: #DFDFDF;
	transition: .6s;
}


.side-navbar-container {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 240px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  /* z-index: 1; */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #385395; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
}

/**/

.side-navbar-items {
	display: flex;
	flex-flow: column;
	padding-bottom: 55px;
    height: calc(100vh - 55px);
}

.navbar-item {
	display: flex;
	flex-flow: column;
	text-align: left;
}

.navbar-item-title {
	font-size: 20px;
	color: #fff;
	margin-bottom: 20px;
	margin-left: 30px;
	margin-top: 25px;
}

.navbar-link {
	font-size: 18px;
	color: #fff;
	-webkit-text-decoration-line: none;
	        text-decoration-line: none;
	margin-bottom: 15px;
	margin-left: 50px;
}

.navbar-link:hover {
	cursor: pointer;
	color: #E1E1E1;
}


/**/

.navbar-logo {
	margin-bottom: 10px;
	padding-top: 55px;
}

.logo {
	/* width: 180px; */
}

/**/


.navbar-line-breaker {
    border: 0.3px solid #FFFFFF;
	width: 180px;
	margin-top: auto;
}


/* On screens that are 1280px or less */
@media screen and (max-width: 1280px) {

/*	.navbar-logo {
		margin-top: 25px;
		margin-bottom: 10px;
	}

	.navbar-item h2 {
		font-size: 120%;
		color: #fff;
		margin-bottom: 15px;
		margin-left: 30px;
		margin-top: 25px;
	}

	#navbar-link {
		font-size: 110%;
		color: #fff;
		text-decoration-line: none;
		margin-left: 50px;
	}*/

}

body {
	background: rgba(57,83,149,0.10);
	height: 100%;
}

.container {
	margin-top: 50px;
	margin-left: 265px;
	margin-right: 25px;
	margin-bottom: 30px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.header-title-text {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}


/* CLIENTS - header options container */
.header-options-container {
	display: flex;
	flex-flow: row;
}

.header-option: {
	margin-left: 15px;
}

.header-button {

}

.search-input {
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 200;
    border: 1px solid #395395;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
    color: #B6B6B6;
    background-color: #fff;
    border: none;
}

.no-items {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.products-container {
	margin-top: 30px;
	margin-left: 0px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
	padding-bottom: 10px;
}

.products-header {
	height: 50px !important;
	color: #B6B6B6 !important;
}

.products-item {
	color: #4A4A4A;
	background-color: white;
	height: 30px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	font-size: 12px;
}

.products-item:hover {
	opacity: 0.7;
	/* cursor: pointer; */
}

.products-item--stripe {
	background-color: #EBEDF4;
}

.products-item-select {
	width: 3%;
}

.products-item-sku {
	padding-left: 10px;
	text-align: left;
	width: 10%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-name {
	text-align: left;
	width: 12%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.products-item-name:hover {
	cursor: pointer;
}

.products-item-description {
	text-align: left;
	width: 22%;
 	white-space: nowrap;
  	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.products-item-brand {
	text-align: left;
	width: 10%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-price {
	text-align: left;
	width: 7%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-rating {
	text-align: left;
	width: 7%;
}

.products-item-rating-code {
	text-align: left;
	width: 7%;
}

.products-item-stock {
	text-align: left;
	width: 7%;
}

.products-item-actions {
	display: flex;
	flex-flow: row nowrap;
	text-align: left;
	justify-content: flex-end;
	width: 15%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-actions > div {
	margin-right: 10px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-item-actions > div:hover {
	opacity: 0.5;
	cursor: pointer;
}

.load-more-button {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	height: 20px;
	width: 	100px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
}

.load-more-button:hover {
	background-color: #385395;
	color: white;
	transition: .2s;
}

.product-details {
	font-size: 16px;
}

.product-details-media {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 30px;
}

.product-details-media-item {
	width: 100px;
	height: 100px;
	margin-right: 15px;
	margin-top: 15px;
	border: 1px solid lightgrey;
	border-radius: 10px;
	overflow: hidden;
}

.product-details-media-item:hover {
	cursor: pointer;
}

.product-details-media-item-img {
	/* width: 100px; */
	height: 100px;
	margin-right: 15px;
}

.product-details-name {
	font-size: 26px;
	margin-bottom: 15px;
}

.product-details-brand {
	margin-bottom: 10px;
}

.product-details-description {
	margin-bottom: 20px;
}

.product-details-price-field {
	margin-top: 15px;
	display: flex;
	flex-flow: row nowrap;
}

.product-details-price-field-label {
	margin-right: 10px;
	width: 250px;
}

.product-details-price-field-input {
	width: 50px;
	font-size: 16px;
	text-align: right;
	margin: none;
}

.button {
	margin-top: 10px;
	margin-left: auto;
	/* margin-right: auto; */
	height: 20px;
	width: 	220px;
	color: #fff;
	font-style: normal;
	background-color: #385395;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
	border: 1px solid #385395;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.button:hover {
	border: 1px solid #385395;
	background-color: white;
	color: #385395;
	transition: .2s;
}

.button--header {
	margin-top: 0px;
	margin-right:15px;
	width: 75px;
}

.button--header:hover{
	background-color:transparent;
}

.button--inactive {
	opacity: 0.5
}

.icon {
	margin-left: 8px;
	margin-top: 3px;
}

body {
	background: rgba(57,83,149,0.10);
	height: 100%;
}

.container {
	margin-top: 50px;
	margin-left: 265px;
	margin-right: 25px;
	margin-bottom: 30px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.header-title-text {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}

/* CLIENTS - header options container */
.header-options-container {
	display: flex;
	flex-flow: row;
}

.header-option: {
	margin-left: 15px;
}

.header-button {

}

.search-input {
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 200;
    border: 1px solid #395395;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
    color: #B6B6B6;
    background-color: #fff;
    border: none;
}

.no-items {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.orders-container {
	margin-top: 30px;
	margin-left: 0px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
	padding-bottom: 10px;
}

.order-details-container {
	min-width: 700px;
}

.orders-header {
	height: 50px !important;
	color: #B6B6B6 !important;
}

.orders-item {
	color: #4A4A4A;
	background-color: white;
	height: 30px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.orders-item:hover {
	opacity: 0.7;
	/* cursor: pointer; */
}

.orders-item--stripe {
	background-color: #EBEDF4;
}

.orders-item-order-id {
	padding-left: 10px;
	text-align: left;
	width: 5%;
}

.orders-item-skus {
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 30%;
}

.orders-item-shopify-order-id {
	text-align: left;
	width: 15%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.orders-item-shopify-order-id:hover {
	cursor: pointer;
}

.orders-item-shopify-order-number {
	text-align: left;
	width: 15%;
 	white-space: nowrap;
  	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.orders-item-upteam-purchase-order-id {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 15%;
}

.orders-item-upteam-reference {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 15%;
}

.orders-item-status {
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 5%;
}

.order-status {
	margin-left: 5px;
	margin-top: 3px;
	width: 10px;
	height: 10px;
	border-radius: 50px;
}

.order-status--red {
	background-color: #E46666;
}

.order-status--yellow {
	background-color: #FFF097;
}

.order-status--green {
	background-color: #7ADA8F;
}

.load-more-button {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	height: 20px;
	width: 	100px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
}

.load-more-button:hover {
	background-color: #385395;
	color: white;
	transition: .2s;
}

.order-details {
	font-size: 16px;
}

.order-details-name {
	font-size: 26px;
	margin-bottom: 15px;
}

.order-item-sku {
	width: 25%;
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.order-details-field {
	margin-top: 10px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.button {
	margin-top: 10px;
	margin-left: auto;
	/* margin-right: auto; */
	height: 20px;
	width: 	220px;
	color: #fff;
	font-style: normal;
	background-color: #385395;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
	border: 1px solid #385395;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.button:hover {
	border: 1px solid #385395;
	background-color: white;
	color: #385395;
	transition: .2s;
}

.button--header {
	margin-top: 0px;
	margin-right:15px;
	width: 75px;
}

.button--header:hover{
	background-color:transparent;
}

.button--inactive {
	opacity: 0.5
}

.icon {
	margin-left: 8px;
	margin-top: 3px;
}

.margins {

    margin-top: 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.margin-item {
    margin-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.margin-item__block {
    margin-right: 30px;
}

.margin-status {
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.mb-4 {
    margin-bottom: 0;
}
input:focus, textarea, button {
    outline: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.protected-pages {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.disable {
    opacity: 0.5;
}

.table-row:hover {
    opacity: 0.7;
    cursor: pointer;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


html, body {
  margin: 0;
  padding: 0;
}

