.login-logo-container {
	margin-bottom: 60px;
}

.login-logo {
	width: 380px;
}

.login-container {
	background-color: #385395;
   	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}


.login-email-field, .login-password-field {
	width: 330px;
	margin: .5em;
	padding: 11px 30px 11px 30px;
	border-radius: 30px;
	font-size: 25px;
	border: none;
	color: #fff;
	/* font-style: italic; */
	background-color: #8E9CC1;
	font-weight: 200;
}

.login-container input::-webkit-input-placeholder {
   font-style: italic;
}
.login-container input:-moz-placeholder {
   font-style: italic;
}
.login-container input::-moz-placeholder {
   font-style: italic;
}
.login-container input:-ms-input-placeholder {
   font-style: italic;
}

.login-container input::placeholder {
	color: #fff;
}

.sign-in-button {
	height: 50px;
	width: 	295px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	border-radius: 30px;
	margin-top: 35px;
	cursor: pointer;
	border-radius: 30px;
	font-size: 25px;
	border: none;
	font-weight: 300;
}

.sign-in-button:hover {
	background-color: #DFDFDF;
	transition: .6s;
}
