.margins {

    margin-top: 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.margin-item {
    margin-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.margin-item__block {
    margin-right: 30px;
}

.margin-status {
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}