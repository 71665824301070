body {
	background: rgba(57,83,149,0.10);
	height: 100%;
}

.container {
	margin-top: 50px;
	margin-left: 265px;
	margin-right: 25px;
	margin-bottom: 30px;
}

.header {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.header-title-text {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}

/* CLIENTS - header options container */
.header-options-container {
	display: flex;
	flex-flow: row;
}

.header-option: {
	margin-left: 15px;
}

.header-button {

}

.search-input {
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 200;
    border: 1px solid #395395;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
    color: #B6B6B6;
    background-color: #fff;
    border: none;
}

.no-items {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.orders-container {
	margin-top: 30px;
	margin-left: 0px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
	padding-bottom: 10px;
}

.order-details-container {
	min-width: 700px;
}

.orders-header {
	height: 50px !important;
	color: #B6B6B6 !important;
}

.orders-item {
	color: #4A4A4A;
	background-color: white;
	height: 30px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.orders-item:hover {
	opacity: 0.7;
	/* cursor: pointer; */
}

.orders-item--stripe {
	background-color: #EBEDF4;
}

.orders-item-order-id {
	padding-left: 10px;
	text-align: left;
	width: 5%;
}

.orders-item-skus {
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 30%;
}

.orders-item-shopify-order-id {
	text-align: left;
	width: 15%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.orders-item-shopify-order-id:hover {
	cursor: pointer;
}

.orders-item-shopify-order-number {
	text-align: left;
	width: 15%;
 	white-space: nowrap;
  	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
}

.orders-item-upteam-purchase-order-id {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 15%;
}

.orders-item-upteam-reference {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 15%;
}

.orders-item-status {
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 5%;
}

.order-status {
	margin-left: 5px;
	margin-top: 3px;
	width: 10px;
	height: 10px;
	border-radius: 50px;
}

.order-status--red {
	background-color: #E46666;
}

.order-status--yellow {
	background-color: #FFF097;
}

.order-status--green {
	background-color: #7ADA8F;
}

.load-more-button {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	height: 20px;
	width: 	100px;
	color: #385395;
	font-style: normal;
	background-color: #fff;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
}

.load-more-button:hover {
	background-color: #385395;
	color: white;
	transition: .2s;
}

.order-details {
	font-size: 16px;
}

.order-details-name {
	font-size: 26px;
	margin-bottom: 15px;
}

.order-item-sku {
	width: 25%;
	padding-left: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.order-details-field {
	margin-top: 10px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.button {
	margin-top: 10px;
	margin-left: auto;
	/* margin-right: auto; */
	height: 20px;
	width: 	220px;
	color: #fff;
	font-style: normal;
	background-color: #385395;
	padding: 8px 30px 8px 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 30px;
	font-size: 16px;
	border: none;
	font-weight: 300;
	border: 1px solid #385395;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.button:hover {
	border: 1px solid #385395;
	background-color: white;
	color: #385395;
	transition: .2s;
}

.button--header {
	margin-top: 0px;
	margin-right:15px;
	width: 75px;
}

.button--header:hover{
	background-color:transparent;
}

.button--inactive {
	opacity: 0.5
}

.icon {
	margin-left: 8px;
	margin-top: 3px;
}
