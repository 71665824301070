.mb-4 {
    margin-bottom: 0;
}
input:focus, textarea, button {
    outline: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.protected-pages {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;
}

.disable {
    opacity: 0.5;
}

.table-row:hover {
    opacity: 0.7;
    cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


html, body {
  margin: 0;
  padding: 0;
}
